import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDebounce } from 'react-use'

import { Button, Col, Form, Input, Radio, Row, Select, Typography } from 'antd'
import { DeleteOutlined as DeleteIcon, PlusOutlined } from '@ant-design/icons'

import MarkdownEditor from 'components/systems/markdown/editor'
import DateRange from 'components/systems/dateRange'
import { WrapLoading } from 'components/systems/loading'
import UploadImageSync from 'components/systems/uploadPicture/UploadImageSync'

import useCampaign from 'hooks/campaign/useCampaign'
import { useCommunities } from 'hooks/community/useCommunities'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'
import { useCreateCampaign } from 'hooks/campaign/useCreateCampaign'
import { useUpdateCampaign } from 'hooks/campaign/useUpdateCampaign'
import { useBountyInfinite } from 'hooks/bounty/useBounties'
import { useUpdateBountiesOfCampaign } from 'hooks/campaign/useUpdateBountiesOfCampaign'

import { BountyData } from 'services/bountySystem/bounty'

import { generateSlug, notifyError, notifySuccess } from 'helper'

import { ROUTES } from 'constant/routes'
import {
  DESC_MAX_LEN,
  DESC_MIN_LEN,
  TITLE_MAX_LEN,
  TITLE_MIN_LEN,
} from 'constant'
import { ICampaign } from 'types/campaign.type'

const CampaignFormSetUp = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const title = Form.useWatch('title', form)
  const tags = Form.useWatch('tags', form)
  const startedAt = Form.useWatch('startedAt', form)
  const endedAt = Form.useWatch('endedAt', form)

  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useState('')
  const [bounties, setBounties] = useState<BountyData[]>([])

  const campaignId = useParams().campaignId
  const [{ _id: communityId }] = useCurrentCommunity()
  const { communities } = useCommunities({})
  const {
    data: allBounties,
    hasNextPage,
    fetchNextPage,
    refetchRoot: refetchBounties,
    isLoading: fetchingBounty,
  } = useBountyInfinite({
    search,
    pageSize: 100,
  })
  const { data: currentCampaignBounties } = useBountyInfinite({
    campaignId,
    pageSize: 100,
    enabled: !!campaignId,
  })
  const { data: campaignDetails, isLoading } = useCampaign(campaignId)

  const isUpdateCampaign = useMemo(() => Boolean(campaignId), [campaignId])
  useDebounce(
    () => {
      setSearch(keyword)
    },
    500,
    [keyword],
  )

  const { mutateAsync: createCampaign, isLoading: isCreating } =
    useCreateCampaign()
  const { mutateAsync: updateCampaign, isLoading: isUpdating } =
    useUpdateCampaign()
  const { mutateAsync: updateBountiesOfCampaign } =
    useUpdateBountiesOfCampaign()

  const handleSubmit = async () => {
    try {
      let campaign: ICampaign | undefined
      const values = await form.validateFields()
      if (isUpdateCampaign) {
        await updateCampaign({
          campaignId: campaignId!,
          payload: values,
        })
      } else {
        campaign = await createCampaign(values)
      }

      await updateBountiesOfCampaign({
        campaignId: campaign?._id ?? campaignId!,
        bountyIds: bounties.map((bounty) => bounty._id),
      })

      await refetchBounties()
      notifySuccess(`${isUpdateCampaign ? 'Updated' : 'Created'} Campaign`)
      navigate(ROUTES.CAMPAIGN.INDEX)
    } catch (error) {
      notifyError(error)
    }
  }
  const handleSearch = (newValue: string) => {
    setKeyword(newValue)
  }
  const handleChange = (bountyId: string) => {
    setKeyword('')

    const bountyExists =
      bounties.findIndex((bounty) => bounty._id === bountyId) !== -1
    if (bountyExists) return

    const bounty = allBounties.find((bounty) => bounty._id === bountyId)
    if (!bounty) return

    setBounties((prev) => [...prev, bounty])
  }
  const handleRemoveBounty = (bountyId: string) => {
    setBounties((prev) => prev.filter((bounty) => bounty._id !== bountyId))
  }

  useEffect(() => {
    if (campaignDetails) {
      form.setFieldsValue(campaignDetails)
    }

    if (!campaignDetails) {
      form.setFieldValue('communityId', communityId)
    }
  }, [campaignDetails, communityId, form])

  useEffect(() => {
    if (!bounties.length && !!currentCampaignBounties.length) {
      setBounties(currentCampaignBounties)
    }
    // eslint-disable-next-line
  }, [currentCampaignBounties])

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Campaign Info
        </Typography.Title>
      </Col>

      <Col span={24}>
        <WrapLoading loading={isLoading}>
          <Form
            form={form}
            colon={false}
            requiredMark={false}
            labelCol={{ span: 5 }}
            labelAlign="left"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="coverImage"
              label="Cover Image"
              rules={[
                {
                  required: true,
                  message: 'Cover Image is required',
                },
              ]}
            >
              <UploadImageSync />
            </Form.Item>

            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              rules={[
                {
                  required: true,
                  message: 'Thumbnail is required',
                },
              ]}
            >
              <UploadImageSync />
            </Form.Item>

            <Form.Item name="communityId" label="Select Community">
              <Select
                options={communities.map((community) => ({
                  label: community.title,
                  value: community._id,
                }))}
                className="select-community"
              />
            </Form.Item>

            <Form.Item
              name="title"
              label={`Title (${TITLE_MIN_LEN}-${TITLE_MAX_LEN} characters)`}
              rules={[
                { required: true, message: 'Title is required' },
                {
                  min: TITLE_MIN_LEN,
                  max: TITLE_MAX_LEN,
                  message: `Title must be between ${TITLE_MIN_LEN} - ${TITLE_MAX_LEN} characters.`,
                },
              ]}
            >
              <Input placeholder="Enter campaign name..." />
            </Form.Item>

            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required: true, message: 'Slug is require' }]}
            >
              <Input
                placeholder="Generate slug"
                suffix={
                  <Button
                    type="primary"
                    onClick={() => {
                      const campaignSlug = generateSlug(title || '')
                      form.setFieldValue('slug', campaignSlug)
                    }}
                  >
                    Generate slug
                  </Button>
                }
              />
            </Form.Item>

            <Form.Item
              name="description"
              label={`Description (${DESC_MIN_LEN}-${DESC_MAX_LEN} characters)`}
              rules={[
                { required: true, message: 'Description is required' },
                {
                  min: DESC_MIN_LEN,
                  max: DESC_MAX_LEN,
                  message: `Description must be between ${DESC_MIN_LEN} - ${DESC_MAX_LEN} characters.`,
                },
              ]}
            >
              <MarkdownEditor defaultValue={campaignDetails?.description} />
            </Form.Item>

            <Form.Item
              name="tags"
              label="Tags"
              rules={[
                { required: true, message: 'Please enter one tag.' },
                {
                  validator: () => {
                    if (tags && tags.length > 1) {
                      return Promise.reject(new Error('Only 1 tag is allowed!'))
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Enter campaign tag..."
              />
            </Form.Item>

            <Form.Item name="startedAt" rules={[{ required: true }]} hidden />
            <Form.Item name="endedAt" rules={[{ required: true }]} hidden />

            <Form.Item name="isEnabled" label="Status" initialValue={true}>
              <Radio.Group>
                <Radio value={true}>Published</Radio>
                <Radio value={false}>Unpublished</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="campaignDateRange"
              label="Date range"
              rules={[
                {
                  validator: () => {
                    if (!startedAt || !endedAt) {
                      return Promise.reject(
                        new Error('Please select both start and end date'),
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <DateRange
                fromLabel="Start Date (UTC)"
                toLabel="End Date (UTC)"
                startAt={startedAt}
                endAt={endedAt}
                requiredMark={false}
                onChangeStartAt={(value) => {
                  form.setFieldsValue({ startedAt: value })
                  form.setFieldValue('startedAt', value)
                  form.validateFields(['campaignDateRange'])
                }}
                onChangeEndAt={(value) => {
                  form.setFieldValue('endedAt', value)
                  form.validateFields(['campaignDateRange'])
                }}
              />
            </Form.Item>

            <Form.Item
              name="missions"
              label="Missions"
              shouldUpdate={true}
              valuePropName="dummy-value-field"
            >
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                options={allBounties.map((bounty) => ({
                  label: bounty.title,
                  value: bounty._id,
                }))}
                dropdownRender={(menu) => (
                  <>
                    {menu}

                    {hasNextPage && (
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => fetchNextPage()}
                        loading={fetchingBounty}
                        block
                      >
                        Load more
                      </Button>
                    )}
                  </>
                )}
                placeholder="Search missions by name"
                value={keyword}
              />
            </Form.Item>

            <Row style={{ minHeight: 300 }}>
              <Col offset={5}>
                <Row>
                  {bounties.map((bounty) => (
                    <Col span={24} key={bounty._id}>
                      <Button
                        icon={<DeleteIcon />}
                        onClick={() => handleRemoveBounty(bounty._id)}
                      ></Button>
                      <Typography.Text>
                        {bounty._id} - {bounty.title}
                      </Typography.Text>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                loading={isCreating || isUpdating}
              >
                {isUpdateCampaign ? 'Update' : 'Create'}
              </Button>
            </Row>
          </Form>
        </WrapLoading>
      </Col>
    </Row>
  )
}

export default CampaignFormSetUp
