import ListLeaderboardTask from 'components/leaderboard-task/list-task'

import { useAcceler8TaskByGameId } from 'hooks/acceler8/useAcceler8Tasks'
import { useAcceler8TotalParticipantsByProgram } from 'hooks/acceler8/useAcceler8TotalParticipantsByProgram'
import { useAcceler8CurrentSeason } from 'hooks/acceler8/useAcceler8CurrentSeason'
import { useAcceler8TotalPointsByProgram } from 'hooks/acceler8/useAcceler8TotalPointsByProgram'

import configs from 'configs'

import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'

type ListLeaderboardTaskProps = {
  gameId?: string
} & LeaderboardTaskProps

function Acceler8ListLeaderboardTask({
  gameId,
  type,
}: ListLeaderboardTaskProps) {
  const { data } = useAcceler8TaskByGameId({ communityId: gameId, type })
  const { data: season } = useAcceler8CurrentSeason()

  const { data: participants } = useAcceler8TotalParticipantsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId: season?.id,
  })

  const { data: totalPoints } = useAcceler8TotalPointsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId: season?.id,
  })

  return (
    <ListLeaderboardTask
      isSpace3={gameId === configs.community.SP3CommunityId}
      tasks={data?.tasks}
      participants={participants}
      totalPoints={totalPoints}
    />
  )
}

export default Acceler8ListLeaderboardTask
