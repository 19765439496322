import React, { useCallback, useEffect } from 'react'

import { Button, Form, Row } from 'antd'
import { WrapLoading } from 'components/systems/loading'
import BaseInfoSection from '../share/form-section/BaseInfoSection'
import Space3DetailSection from '../share/form-section/Space3DetailSection'

import { useCreateAcceler8Task } from 'hooks/acceler8/useCreateAcceler8Task'
import { useAcceler8CurrentSeason } from 'hooks/acceler8/useAcceler8CurrentSeason'
import { useAcceler8Task } from 'hooks/acceler8/useAcceler8Task'
import { useAcceler8SubscriptionOverall } from 'hooks/acceler8/useAcceler8SubscriptionOverall'
import { useUpdateAcceler8Task } from 'hooks/acceler8/useUpdateAcceler8Task'
import { usePlayerCurrentSeason } from 'hooks/player-leaderboard/usePlayerCurrentSeason'

import configs from 'configs'

import { AC8TaskType, TaskCategory } from 'types/acceler8/acceler8-task.type'
import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

type FormSpace3GameTaskProps = {
  isEdit: boolean
  taskId?: string
} & LeaderboardTaskProps

function FormSpace3GameTask({ isEdit, taskId, type }: FormSpace3GameTaskProps) {
  const [form] = Form.useForm()

  const { data: games } = useAcceler8SubscriptionOverall()
  const space3Subscription = games?.find(
    (game) => game.communityId === configs.community.SP3CommunityId,
  )
  const { data: taskDetail, isLoading } = useAcceler8Task(taskId)
  const { data: currentSeason } = useAcceler8CurrentSeason()
  const { data: playerCurrentSeason } = usePlayerCurrentSeason()
  const { mutateAsync: createTask, isLoading: isLoadingCreate } =
    useCreateAcceler8Task()
  const { mutateAsync: updateTask, isLoading: isLoadingUpdate } =
    useUpdateAcceler8Task()

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields()

    if (isEdit && taskId) {
      const payloadUpdate = {
        ...taskDetail,
        ...values,
      }

      return updateTask({ taskId, dto: payloadUpdate })
    }

    const payloadCreate = {
      ...values,
      category: TaskCategory.Space3,
      type: AC8TaskType.Space3,
      subscriptionId: space3Subscription?.id,
      seasonId:
        type === ProgramType.Team ? currentSeason?.id : playerCurrentSeason?.id,
      communityId: configs.community.SP3CommunityId,
      programType: type,
    }

    return createTask(payloadCreate)
  }, [
    createTask,
    updateTask,
    currentSeason,
    playerCurrentSeason,
    form,
    isEdit,
    space3Subscription,
    taskId,
    taskDetail,
    type,
  ])

  useEffect(() => {
    if (isEdit && taskDetail) {
      form.setFieldsValue(taskDetail)
    }
  }, [taskDetail, isEdit, form])

  useEffect(() => {
    return () => form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapLoading loading={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        colon={false}
        requiredMark={false}
        className="form-space3-task-container"
      >
        <BaseInfoSection form={form} />

        <Space3DetailSection form={form} />

        <Row justify="end">
          <Button
            type="primary"
            htmlType="submit"
            className="form-partner-task--btn-submit"
            loading={isLoadingCreate || isLoadingUpdate}
          >
            {isEdit ? 'Update' : 'Publish'}
          </Button>
        </Row>
      </Form>
    </WrapLoading>
  )
}

export default FormSpace3GameTask
