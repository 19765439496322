import {
  DiscordTaskAction,
  FacebookTaskAction,
  SocialTaskAction,
  SocialTaskProvider,
  SocialTemplateConfigs,
  TaskKeys,
  TaskType,
  TelegramTaskAction,
  TwitterTaskAction,
  YoutubeTaskAction,
} from 'templates/types'
import configs from 'configs'

const SOCIAL_COLOR_CONFIGS = {
  [SocialTaskProvider.Twitter]: '#1DA1F2',
  [SocialTaskProvider.Facebook]: '#2E89FF',
  [SocialTaskProvider.Telegram]: '#4B9BFF',
  [SocialTaskProvider.Youtube]: '#FF0000',
  [SocialTaskProvider.Discord]: '#5866F2',
}

/**
 * thumbnail is unused, use SocialTaskProvider to render corresponding thumbnail
 */
export const TEMPLATE_CONFIGS: Record<SocialTaskAction, SocialTemplateConfigs> =
  {
    [TwitterTaskAction.Follow]: {
      type: TaskType.Social,
      thumbnail: '',
      extra: {
        'meta.targetUserName': {
          componentType: 'Input',
          extraFieldTitle: 'UserName',
          extraFieldPlaceholder: 'Enter Twitter username',
        },
      },
      title: 'Follow on Twitter',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Twitter],
      defaultData: {
        provider: SocialTaskProvider.Twitter,
        action: TwitterTaskAction.Follow,
        meta: {},
      },
    },
    [TwitterTaskAction.Retweet]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Retweet',
      extra: {
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Tweet URL',
          extraFieldPlaceholder:
            'https://twitter.com/Space3_gg/status/1630040242307645441',
        },
      },
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Twitter],
      defaultData: {
        provider: SocialTaskProvider.Twitter,
        action: TwitterTaskAction.Retweet,
        meta: {},
      },
    },
    [TwitterTaskAction.Like]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Like a Tweet',
      extra: {
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Tweet URL',
          extraFieldPlaceholder:
            'https://twitter.com/Space3_gg/status/1630040242307645441',
        },
      },
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Twitter],
      defaultData: {
        provider: SocialTaskProvider.Twitter,
        action: TwitterTaskAction.Like,
        meta: {},
      },
    },
    [TwitterTaskAction.Quote]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Quote a Tweet',
      extra: {
        'meta.contents': {
          componentType: 'MarkdownEditor',
          extraFieldTitle: 'Contents',
          extraFieldPlaceholder: 'Enter Contents',
        },
        'meta.hashtags': {
          componentType: 'Select',
          extraFieldTitle: 'Hashtags',
          extraFieldPlaceholder: 'Enter required hashtags',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Tweet URL',
          extraFieldPlaceholder:
            'https://twitter.com/Space3_gg/status/1630040242307645441',
        },
      },
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Twitter],
      defaultData: {
        provider: SocialTaskProvider.Twitter,
        action: TwitterTaskAction.Quote,
        meta: {},
      },
    },
    [TwitterTaskAction.Reply]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Reply a Tweet',
      extra: {
        'meta.contents': {
          componentType: 'MarkdownEditor',
          extraFieldTitle: 'Reply Contents',
          extraFieldPlaceholder: 'Enter Contents',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Tweet URL',
          extraFieldPlaceholder:
            'https://twitter.com/Space3_gg/status/1630040242307645441',
        },
      },
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Twitter],
      defaultData: {
        provider: SocialTaskProvider.Twitter,
        action: TwitterTaskAction.Reply,
        meta: {},
      },
    },
    [TelegramTaskAction.Join]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Join Group/Channel',
      extra: {
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Group/Channel URL',
          extraFieldPlaceholder: 'https://t.me/space3_gg',
        },
      },
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Telegram],
      defaultData: {
        provider: SocialTaskProvider.Telegram,
        action: TelegramTaskAction.Join,
        meta: {},
      },
    },
    [DiscordTaskAction.Join]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Join Discord',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Discord],
      extra: {
        'meta.guildId': {
          componentType: 'Input',
          extraFieldTitle: 'Server ID',
          extraFieldPlaceholder: '1024717166697775235',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Discord Invite Link',
          extraFieldPlaceholder: 'https://discord.gg/P5d36d9G',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Discord,
        action: DiscordTaskAction.Join,
        meta: {
          redirectUri: configs.user.origin + '/oauth2',
        },
      },
    },
    [DiscordTaskAction.VerifyRole]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Verify Role',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Discord],
      extra: {
        'meta.guildId': {
          componentType: 'Input',
          extraFieldTitle: 'Server ID',
          extraFieldPlaceholder: '1024717166697775235',
        },
        'meta.roleId': {
          componentType: 'Input',
          extraFieldTitle: 'Role ID',
          extraFieldPlaceholder: '1265592349002694199',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Discord Invite Link',
          extraFieldPlaceholder: 'https://discord.gg/P5d36d9G',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Discord,
        action: DiscordTaskAction.VerifyRole,
        meta: {
          redirectUri: configs.user.origin + '/oauth2',
        },
      },
    },
    [FacebookTaskAction.LikePage]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Like Facebook Page',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Facebook],
      extra: {
        'meta.pageId': {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Page ID',
          extraFieldPlaceholder: '114850558235293',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Page Link',
          extraFieldPlaceholder: 'https://www.facebook.com/like.page.mission',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Facebook,
        action: FacebookTaskAction.LikePage,
        meta: {
          redirectUri: configs.user.origin + '/oauth2',
        },
      },
    },
    [FacebookTaskAction.JoinGroup]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Join Facebook Group',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Facebook],
      extra: {
        'meta.groupId': {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Group ID',
          extraFieldPlaceholder: '176612955204920',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Group Link',
          extraFieldPlaceholder:
            'https://www.facebook.com/groups/join.group.mission',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Facebook,
        action: FacebookTaskAction.JoinGroup,
        meta: {
          redirectUri: configs.user.origin + '/oauth2',
        },
      },
    },
    [FacebookTaskAction.LikePost]: {} as SocialTemplateConfigs,
    [FacebookTaskAction.SharePost]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Share Facebook Post',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Facebook],
      extra: {
        'meta.postId': {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Post ID',
          extraFieldPlaceholder: '105011469228425',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Post URL',
          extraFieldPlaceholder:
            'https://www.facebook.com/Space3byA8/posts/pfbid0qcVp1...',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Facebook,
        action: FacebookTaskAction.SharePost,
        meta: {
          redirectUri: configs.user.origin + '/oauth2',
        },
      },
    },
    [FacebookTaskAction.PostTags]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Post Facebook with Tags',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Facebook],
      extra: {
        'meta.tags': {
          componentType: 'Input',
          extraFieldTitle: 'Facebook Hashtags',
          extraFieldPlaceholder: '#space3 #tag',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Facebook,
        action: FacebookTaskAction.PostTags,
        meta: {
          redirectUri: configs.user.origin + '/oauth2',
        },
      },
    },
    [FacebookTaskAction.Follow]: {} as SocialTemplateConfigs,
    [YoutubeTaskAction.Join]: {} as SocialTemplateConfigs,
    [YoutubeTaskAction.Subscribe]: {
      type: TaskType.Social,
      thumbnail: '',
      title: 'Subscribe Youtube Channel',
      color: SOCIAL_COLOR_CONFIGS[SocialTaskProvider.Youtube],
      extra: {
        'meta.targetChannelId': {
          componentType: 'Input',
          extraFieldTitle: 'Channel ID',
          extraFieldPlaceholder: 'UCv4p3wP6A6Li2UYhGsh3tDQ',
        },
        url: {
          componentType: 'Input',
          extraFieldTitle: 'Youtube Link',
          extraFieldPlaceholder: 'https://www.youtube.com/@Ancient8GamingGuild',
        },
      },
      defaultData: {
        provider: SocialTaskProvider.Youtube,
        action: YoutubeTaskAction.Subscribe,
        meta: {
          redirectUri: `${configs.user.origin}/oauth2`,
        },
      },
    },
  }

export const TASK_KEY_TO_SOCIAL_CONFIGS = {
  [TaskKeys.LikeTwitter]: TEMPLATE_CONFIGS[TwitterTaskAction.Like],
  [TaskKeys.FollowTwitter]: TEMPLATE_CONFIGS[TwitterTaskAction.Follow],
  [TaskKeys.RetweetTwitter]: TEMPLATE_CONFIGS[TwitterTaskAction.Retweet],
  [TaskKeys.QuoteTwitter]: TEMPLATE_CONFIGS[TwitterTaskAction.Quote],
  [TaskKeys.ReplyTwitter]: TEMPLATE_CONFIGS[TwitterTaskAction.Reply],
  [TaskKeys.JoinDiscord]: TEMPLATE_CONFIGS[DiscordTaskAction.Join],
  [TaskKeys.VerifyRoleDiscord]: TEMPLATE_CONFIGS[DiscordTaskAction.VerifyRole],
  [TaskKeys.JoinTelegram]: TEMPLATE_CONFIGS[TelegramTaskAction.Join],
  [TaskKeys.LikeFacebookPage]: TEMPLATE_CONFIGS[FacebookTaskAction.LikePage],
  [TaskKeys.ShareFacebookPost]: TEMPLATE_CONFIGS[FacebookTaskAction.SharePost],
  [TaskKeys.PostFacebookTags]: TEMPLATE_CONFIGS[FacebookTaskAction.PostTags],
  [TaskKeys.JoinFacebookGroup]: TEMPLATE_CONFIGS[FacebookTaskAction.JoinGroup],
  [TaskKeys.SubscribeYoutube]: TEMPLATE_CONFIGS[YoutubeTaskAction.Subscribe],
} as Record<TaskKeys, SocialTemplateConfigs>
