import { acceler8Api as api } from 'services/base-axios'

import { getAccessToken } from 'services/utils'

import configs from 'configs'

import {
  Acceler8SeasonWithPoint,
  CreateAcceler8SeasonDto,
  FindAcceler8SeasonDto,
  FindLatestAcceler8SeasonsDto,
} from 'types/acceler8/acceler8-season.type'
import {
  FindTeamRankDto,
  FindRankDetailDto,
  RankDataResponse,
  SyncPreviousRankingDto,
} from 'types/acceler8/acceler8-rank.type'
import {
  IAcceler8Season,
  UpdateAcceler8SeasonDto,
} from 'types/acceler8/acceler8-season.type'
import { DataList } from 'types'
import {
  Acceler8SubscriptionData,
  CreateAcceler8SubscriptionDto,
  FindSubscriptionActivityDto,
  FindSubscriptionOverallDto,
  UpdateAcceler8SubscriptionDto,
} from 'types/acceler8/acceler8-subscription.type'
import {
  CreateAcceler8ProgramDto,
  IAcceler8Program,
  TotalParticipantsOfProgram,
  TotalPointsOfProgram,
  UpdateAcceler8ProgramDto,
} from 'types/acceler8/acceler8-program.type'
import { Acceler8Activity } from 'types/acceler8/acceler8-activity.type'
import {
  FindTeamsRankDto,
  TeamAccumulatedPoints,
  TeamProfile,
  TeamRank,
} from 'types/acceler8/acceler8-team.type'
import {
  CountParticipantByProgramDto,
  FindContributionByProgramDto,
} from 'types/general-leaderboard/general-leaderboard.type'

class Acceler8 {
  private adminPath = '/admin'

  async findCurrentSeason() {
    const { data } = await api.get<IAcceler8Season>('/season/current')
    return data
  }

  async findRanksAndTeams(
    params: FindTeamRankDto,
  ): Promise<DataList<RankDataResponse[]>> {
    const { data } = await api.get<DataList<RankDataResponse[]>>('/rank/list', {
      params,
    })
    return {
      data: data.data,
      total: data.total,
    }
  }

  async getTeamProfile(teamId: number): Promise<TeamProfile> {
    const { data } = await api.get(`/team/${teamId}/profile`)
    return data
  }

  async getTeams(dto: FindTeamsRankDto): Promise<DataList<TeamRank[]>> {
    const { data } = await api.get(`/team`, {
      params: dto,
    })
    return data
  }

  getDownloadTeamAnalyticsUrl(teamId: number) {
    const accessToken = getAccessToken()
    const paramsObj = { teamId: String(teamId), accessToken }

    const searchParams = new URLSearchParams(paramsObj)
    const url = new URL(
      configs.api.acceler8Api + '/admin/team/export-team?' + searchParams,
    )

    return url.toString()
  }

  getDownloadReferrersUrl(seasonId: number | null, userId: string) {
    if (!seasonId || !userId) return
    const accessToken = getAccessToken()
    const paramsObj = { seasonId: String(seasonId), userId, accessToken }

    const searchParams = new URLSearchParams(paramsObj)
    const url = new URL(
      configs.api.acceler8Api +
        '/admin/player-referrer-event/export-referees?' +
        searchParams,
    )

    return url.toString()
  }

  async getTeamAccumulativePoint(
    listTeamIds: number[],
  ): Promise<TeamAccumulatedPoints[]> {
    const { data } = await api.get(`/team/accumulative-points`, {
      params: { teamIds: listTeamIds },
      paramsSerializer: {
        indexes: null,
      },
    })
    return data
  }

  async findRankDetail(params: FindRankDetailDto) {
    const { data } = await api.get('/rank', { params })
    return data
  }

  async findSeasons(params?: FindAcceler8SeasonDto) {
    const { data } = await api.get<DataList<Acceler8SeasonWithPoint[]>>(
      `${this.adminPath}/season`,
      { params },
    )

    return {
      data: data.data,
      total: data.total,
    }
  }

  async findLatestSeasons(params?: FindLatestAcceler8SeasonsDto) {
    const { data } = await api.get<IAcceler8Season[]>('/season/latest', {
      params,
    })

    return data
  }

  async findSubscriptionOverall(params?: FindSubscriptionOverallDto) {
    const { data } = await api.get<DataList<Acceler8SubscriptionData[]>>(
      `${this.adminPath}/subscriptions`,
      {
        params: {
          ...params,
          packageIds: params?.filter?.packageIds,
          statuses: params?.filter?.statuses,
        },
        paramsSerializer: {
          indexes: null,
        },
      },
    )

    return data
  }

  async getSubscriptionActive() {
    const { data } = await api.get<Acceler8SubscriptionData[]>(
      `${this.adminPath}/subscriptions/active`,
    )
    return data
  }

  async findSubscriptionActivity(params?: FindSubscriptionActivityDto) {
    const { filter, ...restParams } = params!
    const { data } = await api.get<DataList<Acceler8Activity[]>>(
      `${this.adminPath}/activity`,
      {
        params: {
          ...restParams,
          packageIds: filter?.packageIds,
          statuses: filter?.statuses,
        },
        paramsSerializer: {
          indexes: null,
        },
      },
    )

    return data
  }

  async findSeason(seasonId?: number) {
    const { data } = await api.get<IAcceler8Season>(`/season/${seasonId}`)
    return data
  }

  async createSeason(dto: CreateAcceler8SeasonDto): Promise<IAcceler8Season> {
    const { data } = await api.post<IAcceler8Season>(
      `${this.adminPath}/season`,
      dto,
    )
    return data
  }

  async updateSeason(
    seasonId: number,
    dto: UpdateAcceler8SeasonDto,
  ): Promise<IAcceler8Season> {
    const { data } = await api.put<IAcceler8Season>(
      `${this.adminPath}/season/${seasonId}`,
      dto,
    )
    return data
  }

  async updateSubscription(
    subscriptionId: number,
    dto: UpdateAcceler8SubscriptionDto,
  ): Promise<Acceler8SubscriptionData> {
    const { data } = await api.patch<Acceler8SubscriptionData>(
      `${this.adminPath}/subscriptions/${subscriptionId}`,
      dto,
    )
    return data
  }

  async createSubscription(
    dto: CreateAcceler8SubscriptionDto,
  ): Promise<Acceler8SubscriptionData> {
    const { data } = await api.post<Acceler8SubscriptionData>(
      `${this.adminPath}/subscriptions`,
      dto,
    )
    return data
  }

  async getProgram(programId: number) {
    const { data } = await api.get<IAcceler8Program>(
      `${this.adminPath}/program/${programId}`,
    )
    return data
  }

  async createProgram(
    dto: CreateAcceler8ProgramDto,
  ): Promise<IAcceler8Program> {
    const { data } = await api.post(`${this.adminPath}/program`, dto)
    return data
  }

  async updateProgram(programId: number, dto: UpdateAcceler8ProgramDto) {
    const { data } = await api.patch(
      `${this.adminPath}/program/${programId}`,
      dto,
    )
    return data
  }

  async deleteProgram(programId: number) {
    const { data } = await api.delete(`${this.adminPath}/program/${programId}`)
    return data
  }

  async syncRanking({
    currentSeasonId,
    previousSeasonId,
  }: SyncPreviousRankingDto) {
    await api.post(`${this.adminPath}/rank/sync-ranking`, {
      currentSeasonId,
      previousSeasonId,
    })
  }

  async getAcceler8SeasonBefore(
    targetSeasonId: number,
  ): Promise<IAcceler8Season | null> {
    const { data } = await api.get<IAcceler8Season | null>(
      `/season/${targetSeasonId}/before`,
    )

    return data
  }

  async countParticipantsByProgram(
    dto: CountParticipantByProgramDto,
  ): Promise<TotalParticipantsOfProgram[]> {
    const { data } = await api.get(
      `${this.adminPath}/contribution/total-participant`,
      { params: dto, paramsSerializer: { indexes: null } },
    )
    return data
  }

  async getTotalPointsByProgram(
    dto: FindContributionByProgramDto,
  ): Promise<TotalPointsOfProgram[]> {
    const { data } = await api.get(
      `${this.adminPath}/contribution/total-point`,
      { params: dto, paramsSerializer: { indexes: null } },
    )
    return data
  }
}

export const Acceler8Service = new Acceler8()
