import { useCallback } from 'react'
import moment from 'moment'

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import IonIcon from 'components/systems/ionIcon'
import UploadPicture from 'components/systems/uploadPicture'
import CardInfo from 'components/systems/cardInfo'
import { onConvertFileList } from 'components/systems/uploadPicture/upload'

import { useStorage } from 'hooks/systems/useStorage'

import { convertTzUtcBeforeUpload } from 'helper'

import { TIME_FORMAT } from 'constant'

import { Acceler8SeasonPrize } from 'types/acceler8/acceler8-season.type'
import { PlayerSeasonInitialData } from './FormCreate'
import {
  CreatePlayerSeasonDto,
  UpdatePlayerSeasonDto,
} from 'types/player-leaderboard/player-leaderboard-season.type'

type FromUpdateProps = {
  initialSeasonData: PlayerSeasonInitialData
  mutateLoading: boolean
  onMutate: (
    seasonData: CreatePlayerSeasonDto | UpdatePlayerSeasonDto,
    seasonId?: number,
  ) => void
}

function FormSetup({
  initialSeasonData,
  mutateLoading,
  onMutate,
}: FromUpdateProps) {
  const [form] = Form.useForm<PlayerSeasonInitialData | null>()
  const startedAt = Form.useWatch('startedAt', form)
  const endedAt = Form.useWatch('endedAt', form)
  const banner = Form.useWatch('banner', form)

  const { onUpload, uploading } = useStorage(604800)

  const onSave = useCallback(async () => {
    try {
      const values = await form.validateFields()
      if (!values) return

      const newSeasonData: CreatePlayerSeasonDto | UpdatePlayerSeasonDto = {
        ...values,
        startedAt: values.startedAt!,
        endedAt: values.endedAt!,
        prizeStructures: values.prizeStructures.filter(
          (prizeInfo) => prizeInfo.title && prizeInfo.priceText,
        ),
      }
      onMutate(newSeasonData, values.id)
    } catch (error) {
      // to prevent un catch error
    }
  }, [onMutate, form])

  return (
    <Form
      layout="vertical"
      initialValues={initialSeasonData}
      form={form}
      size="middle"
      validateTrigger="onSubmit"
    >
      <Form.Item name="id" hidden />
      <Form.Item
        label={
          <Typography.Text type="secondary">
            Title (5-64 characters)
          </Typography.Text>
        }
        name="title"
        rules={[
          {
            required: true,
            max: 64,
            min: 5,
            message: 'Title must be 5 - 64 characters',
          },
        ]}
      >
        <Input placeholder="Enter title" />
      </Form.Item>
      <Form.Item
        label={
          <Typography.Text type="secondary">
            Sub title (max 64 characters)
          </Typography.Text>
        }
        name="subTitle"
        rules={[
          {
            required: false,
            max: 64,
            message: 'Subtitle must be 64 characters max',
          },
        ]}
      >
        <Input placeholder="Enter subtitle" />
      </Form.Item>

      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select date',
              },
            ]}
            label={<Typography.Text type="secondary">Start</Typography.Text>}
            name={'startedAt'}
            valuePropName="dummy_value"
          >
            <DatePicker
              onChange={(date) =>
                form.setFieldValue(
                  'startedAt',
                  date ? convertTzUtcBeforeUpload(date) : '',
                )
              }
              style={{ width: '100%' }}
              format={TIME_FORMAT}
              suffixIcon={<IonIcon name="calendar-outline" />}
              allowClear
              showTime={{ defaultValue: moment.utc('00:00:00', 'HH:mm:ss') }}
              value={startedAt ? moment.utc(startedAt) : undefined}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select date',
              },
            ]}
            name="endedAt"
            label={<Typography.Text type="secondary">End</Typography.Text>}
            valuePropName="dummy_value"
          >
            <DatePicker
              onChange={(date) =>
                form.setFieldValue(
                  'endedAt',
                  date ? convertTzUtcBeforeUpload(date) : '',
                )
              }
              style={{ width: '100%' }}
              format={TIME_FORMAT}
              suffixIcon={<IonIcon name="calendar-outline" />}
              allowClear
              showTime={{ defaultValue: moment.utc('00:00:00', 'HH:mm:ss') }}
              value={endedAt ? moment.utc(endedAt) : undefined}
              disabledDate={(currentDate) => currentDate.isBefore(startedAt)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <CardInfo title="Call to action">
          <Form.Item
            label={<Typography.Text type="secondary">Title</Typography.Text>}
            required
            name={['callToAction', 'title']}
            rules={[
              {
                required: true,
                max: 64,
                whitespace: true,
                message: 'Title must be 1 - 64 characters',
              },
            ]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>
          <Form.Item
            label={<Typography.Text type="secondary">Link</Typography.Text>}
            name={['callToAction', 'link']}
            rules={[
              {
                required: true,
                message: 'Invalid link',
              },
            ]}
          >
            <Input placeholder="Enter link" type="url" />
          </Form.Item>
        </CardInfo>
      </Form.Item>

      <Form.Item
        name="banner"
        label={<Typography.Text type="secondary">Banner</Typography.Text>}
        required
        rules={[{ message: 'Please upload a banner', required: true }]}
      >
        <div>
          <UploadPicture
            maxCount={1}
            fileList={banner ? onConvertFileList([banner]) : []}
            onChangeFile={async ({ fileList }) => {
              if (!fileList || !fileList[0].originFileObj) return

              let banner = ''
              if (fileList && fileList[0].originFileObj)
                banner = await onUpload(fileList[0])
              form.setFieldValue('banner', banner)
            }}
            loading={uploading}
          />
        </div>
      </Form.Item>

      <Form.Item
        label={
          <Typography.Text type="secondary">Total rewards</Typography.Text>
        }
        required
        name="totalRewards"
        rules={[
          {
            required: true,
            message: 'Total rewards must be greater or equal 0',
          },
        ]}
      >
        <Input placeholder="0" type="number" />
      </Form.Item>

      <Form.Item>
        <CardInfo title="Referral Percents">
          <Form.List
            name="referralPercents"
            rules={[
              {
                validator: (_, referralPercents) => {
                  if (!referralPercents || referralPercents.length < 1) {
                    return Promise.reject(new Error('At least one input'))
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            {(subFields, subOpt, { errors }) => (
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Form.ErrorList errors={errors} />
                </Col>
                {subFields.map((subField, index) => (
                  <Col key={subField.key} span={24}>
                    <Space>
                      <Form.Item noStyle>
                        <Typography.Text>Level {index + 1}</Typography.Text>
                      </Form.Item>
                      <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                        {({ getFieldValue }) => (
                          <Form.Item
                            name={subField.name}
                            style={{ width: 250, marginBottom: 0 }}
                          >
                            <Input
                              min={0}
                              max={100}
                              placeholder="Please enter the percent"
                              suffix={
                                <Tag title="Extra information" color="green">
                                  ={' '}
                                  {Math.floor(
                                    (getFieldValue([
                                      'referralPercents',
                                      subField.name,
                                    ]) ?? 0) * 10000, //get only 2 decimals
                                  ) / 100}
                                  %
                                </Tag>
                              }
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => subOpt.remove(subField.name)}
                      />
                    </Space>
                  </Col>
                ))}
                <Col span={24}>
                  <Button
                    type="dashed"
                    onClick={() => subOpt.add()}
                    block
                    disabled={subFields.length === 5}
                  >
                    + Add
                  </Button>
                </Col>
              </Row>
            )}
          </Form.List>
        </CardInfo>
      </Form.Item>

      <Form.Item>
        <CardInfo title="Prize structure">
          <Form.List
            name="prizeStructures"
            rules={[
              {
                validator: (_, value: Acceler8SeasonPrize[]) => {
                  const valid = value.some(
                    (prize) => prize.title && prize.priceText,
                  )
                  if (valid) return Promise.resolve()

                  return Promise.reject(new Error('Input at least one prize'))
                },
              },
            ]}
          >
            {(subFields, subOpt, { errors }) => (
              <Row gutter={[0, 12]}>
                <Col span={24}>
                  <Form.ErrorList errors={errors} />
                </Col>
                {subFields.map((subField) => (
                  <Col key={subField.key} span={24}>
                    <Space>
                      <Form.Item noStyle name={[subField.name, 'title']}>
                        <Input placeholder="title" />
                      </Form.Item>
                      <Form.Item noStyle name={[subField.name, 'priceText']}>
                        <Input placeholder="priceText" />
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          subOpt.remove(subField.name)
                        }}
                      />
                    </Space>
                  </Col>
                ))}
                <Col span={24}>
                  <Button type="dashed" onClick={() => subOpt.add()} block>
                    + Add prize
                  </Button>
                </Col>
              </Row>
            )}
          </Form.List>
        </CardInfo>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ minWidth: 130 }}
          onClick={() => onSave()}
          loading={mutateLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FormSetup
