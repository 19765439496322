import ListLeaderboardTask from 'components/leaderboard-task/list-task'

import { useAcceler8TaskByGameId } from 'hooks/acceler8/useAcceler8Tasks'
import { usePlayerCurrentSeason } from 'hooks/player-leaderboard/usePlayerCurrentSeason'
import { usePlayerTotalParticipantsByProgram } from 'hooks/player-leaderboard/usePlayerTotalParticipantsByProgram'
import { usePlayerTotalPointsByProgram } from 'hooks/player-leaderboard/usePlayerTotalPointsByProgram'

import configs from 'configs'

import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'

type ListLeaderboardTaskProps = {
  gameId?: string
} & LeaderboardTaskProps

function PlayerListLeaderboardTask({ gameId, type }: ListLeaderboardTaskProps) {
  const { data } = useAcceler8TaskByGameId({ communityId: gameId, type })
  const { data: season } = usePlayerCurrentSeason()

  const { data: participants } = usePlayerTotalParticipantsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId: season?.id,
  })

  const { data: totalPoints } = usePlayerTotalPointsByProgram({
    programIds: data?.tasks.map((task) => task.programId) ?? [],
    seasonId: season?.id,
  })

  return (
    <ListLeaderboardTask
      isSpace3={gameId === configs.community.SP3CommunityId}
      tasks={data?.tasks}
      participants={participants}
      totalPoints={totalPoints}
    />
  )
}

export default PlayerListLeaderboardTask
