import { acceler8Api as api } from 'services/base-axios'

import { DataList } from 'types'
import {
  TotalParticipantsOfProgram,
  TotalPointsOfProgram,
} from 'types/acceler8/acceler8-program.type'
import { PlayerRedemption } from 'types/acceler8/acceler8-redemption.type'
import {
  CountParticipantByProgramDto,
  FindContributionByProgramDto,
  ImportPlayerRanksDto,
} from 'types/general-leaderboard/general-leaderboard.type'

import {
  FindPlayerRankDto,
  GetPlayerPointsDistributionDto,
  PlayerAccumulativePoints,
  PlayerPointsDistribution,
  PlayerRankDataResponse,
  UpdateReferralPercent,
} from 'types/player-leaderboard/player-leaderboard-rank.type'
import {
  FindPlayerRedemptions,
  ImportPlayerRedemptions,
} from 'types/player-leaderboard/player-leaderboard-redemption.type'
import {
  CreatePlayerSeasonDto,
  FindLatestPlayerSeasonsDto,
  FindPlayerSeasonDto,
  IPlayerSeason,
  PlayerSeasonWithPoint,
  UpdatePlayerSeasonDto,
} from 'types/player-leaderboard/player-leaderboard-season.type'

class PlayerLeaderboard {
  private playerRankController = '/admin/player-rank'
  private playerSeasonController = '/admin/player-season'
  private playerContributionController = '/admin/player-contribution'
  private playerRedemptionController = '/admin/redemption'

  async findPlayerRanks(
    params: FindPlayerRankDto,
  ): Promise<DataList<PlayerRankDataResponse[]>> {
    const { data } = await api.get<DataList<PlayerRankDataResponse[]>>(
      `${this.playerRankController}/list`,
      { params, paramsSerializer: { indexes: null } },
    )
    return {
      data: data.data,
      total: data.total,
    }
  }

  async findSeasons(params?: FindPlayerSeasonDto) {
    const { data } = await api.get<DataList<PlayerSeasonWithPoint[]>>(
      this.playerSeasonController,
      { params },
    )

    return {
      data: data.data,
      total: data.total,
    }
  }

  async findLatestSeasons(params?: FindLatestPlayerSeasonsDto) {
    const { data } = await api.get<IPlayerSeason[]>('/player-season/latest', {
      params,
    })

    return data
  }

  async findSeason(seasonId?: number) {
    const { data } = await api.get<IPlayerSeason>(`/player-season/${seasonId}`)
    return data
  }

  async findCurrentSeason() {
    const { data } = await api.get<IPlayerSeason>('/player-season/current')
    return data
  }

  async createSeason(dto: CreatePlayerSeasonDto): Promise<IPlayerSeason> {
    const { data } = await api.post<IPlayerSeason>(
      this.playerSeasonController,
      dto,
    )
    return data
  }

  async updateSeason(
    seasonId: number,
    dto: UpdatePlayerSeasonDto,
  ): Promise<IPlayerSeason> {
    const { data } = await api.put<IPlayerSeason>(
      `${this.playerSeasonController}/${seasonId}`,
      dto,
    )
    return data
  }

  async getPlayerSeasonBefore(
    targetSeasonId: number,
  ): Promise<IPlayerSeason | null> {
    const { data } = await api.get<IPlayerSeason | null>(
      `/player-season/${targetSeasonId}/before`,
    )

    return data
  }

  async getAccumulativePoints(
    userIds: string[],
  ): Promise<PlayerAccumulativePoints[]> {
    const { data } = await api.get(
      `${this.playerRankController}/accumulative-points`,
      {
        params: { userIds },
        paramsSerializer: {
          indexes: null,
        },
      },
    )
    return data
  }

  async countParticipantsByProgram(
    dto: CountParticipantByProgramDto,
  ): Promise<TotalParticipantsOfProgram[]> {
    const { data } = await api.get(
      `${this.playerContributionController}/total-participant`,
      { params: dto, paramsSerializer: { indexes: null } },
    )
    return data
  }

  async getTotalPointsByProgram(
    dto: FindContributionByProgramDto,
  ): Promise<TotalPointsOfProgram[]> {
    const { data } = await api.get(
      `${this.playerContributionController}/total-point`,
      { params: dto, paramsSerializer: { indexes: null } },
    )
    return data
  }

  async importPlayerRanks(
    dto: ImportPlayerRanksDto,
  ): Promise<TotalPointsOfProgram[]> {
    const { data } = await api.post(
      `${this.playerRankController}/import-csv`,
      {
        FormData: dto,
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    return data
  }

  async findRedemptions(params?: FindPlayerRedemptions) {
    const { data } = await api.get<DataList<PlayerRedemption[]>>(
      `${this.playerRedemptionController}`,
      {
        params,
        paramsSerializer: {
          indexes: null,
        },
      },
    )

    return {
      data: data.data,
      total: data.total,
    }
  }

  async importPlayerRedemptions(dto: ImportPlayerRedemptions): Promise<any> {
    const { data } = await api.post(
      `${this.playerRedemptionController}/import`,
      {
        FormData: dto,
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    return data
  }

  async updateReferralPercent({
    seasonId,
    userId,
    referralPercents,
  }: UpdateReferralPercent) {
    const { data } = await api.patch<PlayerRankDataResponse>(
      this.playerRankController,
      { referralPercents },
      {
        params: {
          seasonId,
          userId,
        },
      },
    )

    return data
  }

  async getUserPointsDistribution(
    params: GetPlayerPointsDistributionDto,
  ): Promise<PlayerPointsDistribution[]> {
    const { data } = await api.get(
      `${this.playerContributionController}/user-points-distribution`,
      { params },
    )

    return data
  }
}

export const PlayerLeaderboardService = new PlayerLeaderboard()
