import { useQuery } from 'react-query'

import { Acceler8TaskService } from 'services/acceler8-task'

import { FindAcceler8TaskDto } from 'types/acceler8/acceler8-task.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

export const useAcceler8Tasks = (dto: FindAcceler8TaskDto) => {
  const { data, ...rest } = useQuery({
    queryKey: ['get-acceler8-task', dto],
    queryFn: () => Acceler8TaskService.findAll(dto),
  })

  return {
    ...rest,
    data: data ?? [],
  }
}

type Acceler8TaskByGameIdProps = {
  communityId?: string
  type: ProgramType
}
export const useAcceler8TaskByGameId = ({
  communityId,
  type,
}: Acceler8TaskByGameIdProps) => {
  const { data } = useAcceler8Tasks({ programType: type })

  return { data: data.find((item) => item._id === communityId) }
}
