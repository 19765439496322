import { Navigate, Route, Routes } from 'react-router-dom'

import { Col, Result, Row, Typography } from 'antd'

import Header from './header'
import Sidebar from './sidebar'
import Footer from './footer'
import Loading from 'components/systems/loading'

/** Admin */
import MemberManagement from './admin/member'
import BountyManagement from './bounty/management'
import SummaryReport from './bounty/summaryReport'
import CreateAndUpdate from './bounty/management/createAndUpdate'
import AdminBountyDetails from './bounty/management/bountyDetails'
import UserCompleted from './bounty/management/bountyDetails/userCompleted'
import NftBadge from './admin/badge'
import NewNftBadge from './admin/badge/newBadge'
import NftDetails from './admin/badge/badgeDetails'
import ItemManagement from 'view/itemManagement'
import UsersShipping from './admin/marketplace/usersShipping'
import OrderDetail from './admin/marketplace/usersShipping/orderDetail'
import CreatePhysicalItem from 'view/itemManagement/createPhysicalItem'
import CollectionManagement from 'view/admin/marketplace/collectionManagement'
import NewSolanaNFT from './admin/mintNFT/solana'
import MintNft1155 from './admin/mintNFT/nft1155'
import CollectionDetails from './admin/marketplace/collectionManagement/collectionDetails'
import CategoryManagement from './categoryManagement/listCategory'
import CreateCategory from './categoryManagement/createCategory'
import EditCategory from './categoryManagement/editCategory'
import NewOrEditCollection from './admin/marketplace/collectionManagement/newOrEditCollection'
import EditPhysicalItem from 'view/itemManagement/editPhysicalItem'
import CreateDigitalItem from './itemManagement/createDigitalItem'
import EditDigitalItem from './itemManagement/editDigitalItem'
import DailyCheckIn from './dailyCheckin/streakChecking'
import DailyCheckInReport from './dailyCheckin/summaryReport'
import CCPLeaderboard from './bounty/ccpLeaderboard'
import CommunityManagement from './community/management'
import CreateCommunity from './community/management/createCommunity'
import EditCommunity from './community/management/editCommunity'
import HeroBanner from './config/heroBanner'
import Priorities from './config/priorities'
import CommunityProfile from 'view/community/management/profile'
import { CommunitySetting } from './communitySetting'
// Digital Cards
import DigitalCardsManagement from './admin/marketplace/digitalCardsManagement'
import CreateDigitalCard from './admin/marketplace/digitalCardsManagement/createAndUpdate/CreateDigitalCard'
import UpdateDigitalCard from './admin/marketplace/digitalCardsManagement/createAndUpdate/UpdateDigitalCard'
import DigitalCardDetails from './admin/marketplace/digitalCardsManagement/digitalCardDetails'
// Leaderboard
import Leaderboard from './leaderboard/seasonManagement'
import SeasonDetails from './leaderboard/seasonManagement/seasonDetails'
import CreateOrUpdateSeason from './leaderboard/seasonManagement/createAndUpdate'
import CreateCommunitiesList from './leaderboard/seasonManagement/seasonDetails/listCommunityLeaderboard/createAndUpdate/createCommunitiesList'
import EditCommunityLeaderboard from './leaderboard/seasonManagement/seasonDetails/listCommunityLeaderboard/createAndUpdate/editCommunityLeaderboard'
import CreateTeamLeaderboard from './leaderboard/seasonManagement/seasonDetails/listTeamsLeaderboard/createAndUpdate/createTeamLeaderboard'
import EditTeamLeaderboard from './leaderboard/seasonManagement/seasonDetails/listTeamsLeaderboard/createAndUpdate/editTeamLeaderboard'
// Affiliation
import AffiliationSeasonManagement from './affiliation-v2/season'
import SubscriptionManagement from './affiliation/subscriptionManagement'
import AffiliationSeasonForm from './affiliation-v2/season/createAndUpdate'
import TeamManagement from './affiliation/teamManagement'
import TeamDetails from './affiliation/teamManagement/teamDetails'
import AffiliationSeasonDetail from './affiliation-v2/season/detail'
// Acceler8
import Acceler8SeasonManagement from './acceler8/season'
import Acceler8SeasonForm from './acceler8/season/createAndUpdate'
import Acceler8SeasonDetail from './acceler8/season/detail'
import Acceler8TeamManagement from './acceler8/teamManagement'
import Acceler8TeamDetails from './acceler8/teamManagement/teamDetails'
import Acceler8SubscriptionManagement from './acceler8/subscriptionManagement'
import Acceler8SyncRanking from './acceler8/season/syncRanking'
// Player Leaderboard
import ListPlayerSeason from './player-leaderboard/season/list-season'
import PlayerSeasonForm from './player-leaderboard/season/create-and-update'
import PlayerSeasonDetail from './player-leaderboard/season/detail'
import PlayerManagement from './player-leaderboard/user'
import PlayerRedemptionManagement from './player-leaderboard/redemption'
import EditPlayerRank from './player-leaderboard/user/detail'
// Gacha
import GachaManagement from './gacha/management'
import GachaDetail from './gacha/detail'
import CreateUpdateGacha from './gacha/create-update'
import MintGachaTicket from './gacha/mint-ticket'
// Ads
import AdsManagement from './ads/management'
import CreateUpdateAds from './ads/create-update'

// Redemption
import RedemptionManagement from './redemption/management'
import CreateUpdateRedemption from './redemption/create-update'

/** Components */
import LeaderboardTaskProvider from 'components/leaderboard-task'
import Breadcrumb from 'components/systems/breadcrumb'
import PrivateRoutes from 'components/systems/privateRoutes'
import Watcher from './watcher'
import MintA8NFT from './admin/mintNFT/a8nft'
import CampaignFormSetUp from 'components/campaign/campaignFormSetup'
import CampaignManagement from 'view/campaign'
import PartnerGameTask from 'components/leaderboard-task/partner-game-task'
import Space3GameTask from 'components/leaderboard-task/space3-game-task'

import { Role, useUser } from 'hooks/community/useUser'
import { useIsLoggedIn } from 'hooks/user/useIsLoggedIn'

import { ROUTES } from 'constant/routes'

import { ProgramType } from 'types/acceler8/acceler8-program.type'

import 'swiper/css/pagination'
import 'swiper/css'
import './index.less'
import 'static/styles/index.less'

const { Admin, Operator } = Role

const View = () => {
  const logged = useIsLoggedIn()
  const { data: userData } = useUser()

  if (!logged)
    return (
      <Row>
        <Col span={24} style={{ position: 'sticky', top: 0, zIndex: 999 }}>
          <Header />
        </Col>
        <Col span={24}>
          <Result
            status="403"
            title="Login first"
            subTitle={
              <Typography.Text type="warning">
                Sorry, you need to login first to access this page.
              </Typography.Text>
            }
          />
        </Col>
      </Row>
    )

  if (![Operator, Admin].includes(userData?.data.role!))
    return (
      <Row>
        <Col span={24} style={{ position: 'sticky', top: 0, zIndex: 999 }}>
          <Header />
        </Col>
        <Col span={24}>
          <Result
            status="403"
            title="Invalid Permission"
            subTitle={
              <Typography.Text type="warning">
                Sorry, you are not authorized to access this page.
              </Typography.Text>
            }
          />
        </Col>
        <Loading type="page" loading={!userData?.data} />
      </Row>
    )

  return (
    <Row>
      <Col span={24} style={{ position: 'sticky', top: 0, zIndex: 999 }}>
        <Header />
      </Col>
      <Col span={24}>
        <Row gutter={[0, 24]} wrap={false}>
          <Col className="sidebar">
            <Sidebar />
          </Col>
          <Col
            style={{
              flex: '1 1 100%',
              paddingLeft: 45,
              paddingRight: 45,
              paddingBottom: 64,
              paddingTop: 24,
            }}
          >
            <Row justify="center">
              <Col span={24}>
                <Breadcrumb style={{ marginBottom: 16 }} />
              </Col>
              <Col span={24} style={{ maxWidth: 1440 }}>
                <Routes>
                  {/* Start route community */}
                  <Route
                    path={ROUTES.COMMUNITIES.MANAGEMENT}
                    element={<CommunityManagement />}
                  />
                  <Route
                    path={ROUTES.COMMUNITIES.NEW_COMMUNITY}
                    element={<CreateCommunity />}
                  />
                  <Route
                    path={ROUTES.COMMUNITIES.EDIT_COMMUNITY + '/:communityId'}
                    element={<EditCommunity />}
                  />
                  <Route
                    path={ROUTES.COMMUNITIES.EDIT_PROFILE + '/:communityId'}
                    element={<CommunityProfile />}
                  />
                  {/* End route community */}

                  {/* Start route acceler8 */}
                  <Route
                    path={ROUTES.ACCELER8.MANAGE_SEASON}
                    element={<Acceler8SeasonManagement />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.NEW_SEASON}
                    element={<Acceler8SeasonForm />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.VIEW_SEASON + '/:seasonId'}
                    element={<Acceler8SeasonDetail />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.EDIT_SEASON + '/:seasonId'}
                    element={<Acceler8SeasonForm />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.SYNC_RANKING}
                    element={<Acceler8SyncRanking />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.TASK_MANAGEMENT}
                    element={
                      <LeaderboardTaskProvider type={ProgramType.Team} />
                    }
                  />

                  <Route
                    path={ROUTES.ACCELER8.NEW_GAME_TASK}
                    element={<PartnerGameTask type={ProgramType.Team} />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.EDIT_GAME_TASK}
                    element={<PartnerGameTask type={ProgramType.Team} />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.NEW_SPACE3_TASK}
                    element={<Space3GameTask type={ProgramType.Team} />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.EDIT_SPACE3_TASK}
                    element={<Space3GameTask type={ProgramType.Team} />}
                  />

                  <Route
                    path={ROUTES.ACCELER8.SUBSCRIPTION_MANAGEMENT}
                    element={<Acceler8SubscriptionManagement />}
                  />
                  <Route
                    path={ROUTES.ACCELER8.TEAM_MANAGEMENT}
                    element={<Acceler8TeamManagement />}
                  />
                  <Route
                    path={ROUTES.ACCELER8.TEAM_DETAILS}
                    element={<Acceler8TeamDetails />}
                  />
                  {/* End route acceler8 */}

                  {/* Start route Player Leaderboard */}
                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.SEASON_MANAGEMENT}
                    element={<ListPlayerSeason />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.NEW_SEASON}
                    element={<PlayerSeasonForm />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.VIEW_SEASON}
                    element={<PlayerSeasonDetail />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.EDIT_SEASON}
                    element={<PlayerSeasonForm />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.USER_MANAGEMENT}
                    element={<PlayerManagement />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.REDEMPTION_MANAGEMENT}
                    element={<PlayerRedemptionManagement />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.EDIT_USER}
                    element={<EditPlayerRank />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.TASK_MANAGEMENT}
                    element={
                      <LeaderboardTaskProvider type={ProgramType.Player} />
                    }
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.NEW_GAME_TASK}
                    element={<PartnerGameTask type={ProgramType.Player} />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.EDIT_GAME_TASK}
                    element={<PartnerGameTask type={ProgramType.Player} />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.NEW_SPACE3_TASK}
                    element={<Space3GameTask type={ProgramType.Player} />}
                  />

                  <Route
                    path={ROUTES.PLAYER_LEADERBOARD.EDIT_SPACE3_TASK}
                    element={<Space3GameTask type={ProgramType.Player} />}
                  />
                  {/* End route Player Leaderboard */}

                  {/* Start route affiliation */}
                  <Route
                    path={ROUTES.AFFILIATION.SUBSCRIPTION_MANAGEMENT}
                    element={<SubscriptionManagement />}
                  />
                  <Route
                    path={ROUTES.AFFILIATION.TEAM_MANAGEMENT}
                    element={<TeamManagement />}
                  />
                  <Route
                    path={ROUTES.AFFILIATION.TEAM_DETAILS}
                    element={<TeamDetails />}
                  />
                  {/* End route affiliation */}

                  {/* Start route leaderboard */}
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path={ROUTES.LEADERBOARD.INDEX}
                      element={<Leaderboard />}
                    />
                    <Route
                      path={ROUTES.LEADERBOARD.INDEX + '/:seasonId'}
                      element={<SeasonDetails />}
                    />
                    <Route
                      path={ROUTES.LEADERBOARD.NEW_SEASON}
                      element={<CreateOrUpdateSeason />}
                    />
                    <Route
                      path={ROUTES.LEADERBOARD.EDIT_SEASON + '/:seasonId'}
                      element={<CreateOrUpdateSeason />}
                    />

                    <Route
                      path={ROUTES.LEADERBOARD.NEW_TEAM_LEADERBOARD}
                      element={<CreateTeamLeaderboard />}
                    />
                    <Route
                      path={
                        ROUTES.LEADERBOARD.EDIT_TEAM_LEADERBOARD + '/:teamId'
                      }
                      element={<EditTeamLeaderboard />}
                    />

                    <Route
                      path={ROUTES.LEADERBOARD.NEW_COMMUNITY_LEADERBOARD}
                      element={<CreateCommunitiesList />}
                    />
                    <Route
                      path={
                        ROUTES.LEADERBOARD.EDIT_COMMUNITY_LEADERBOARD +
                        '/:communityLeaderboardId'
                      }
                      element={<EditCommunityLeaderboard />}
                    />
                  </Route>

                  {/* End route community */}

                  <Route
                    path={ROUTES.AFFILIATION_V2.MANAGE_SEASON}
                    element={<AffiliationSeasonManagement />}
                  />
                  <Route
                    path={ROUTES.AFFILIATION_V2.NEW_SEASON}
                    element={<AffiliationSeasonForm />}
                  />
                  <Route
                    path={ROUTES.AFFILIATION_V2.VIEW_SEASON}
                    element={<AffiliationSeasonDetail />}
                  />

                  {/* Start route leaderboard */}
                  <Route
                    path={ROUTES.CONFIG.HEROBANNER}
                    element={<HeroBanner />}
                  />
                  <Route
                    path={ROUTES.CONFIG.PRIORITIES}
                    element={<Priorities />}
                  />
                  {/* End route config */}

                  {/* Start route marketplace */}
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path={ROUTES.REWARDS_CENTER.ITEMS}
                      element={<ItemManagement />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.USER_SHIPPING}
                      element={<UsersShipping />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.USER_SHIPPING + '/:orderId'}
                      element={<OrderDetail />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.DIGITAL_CARDS_MANAGEMENT}
                      element={<DigitalCardsManagement />}
                    />
                    <Route
                      path={
                        ROUTES.REWARDS_CENTER.DIGITAL_CARDS_MANAGEMENT +
                        '/:digitalCardId'
                      }
                      element={<DigitalCardDetails />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.NEW_DIGITAL_CARDS}
                      element={<CreateDigitalCard />}
                    />
                    <Route
                      path={
                        ROUTES.REWARDS_CENTER.EDIT_DIGITAL_CARDS +
                        '/:digitalCardId'
                      }
                      element={<UpdateDigitalCard />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.NEW_ITEM_DIGITAL}
                      element={<CreateDigitalItem />}
                    />
                    <Route
                      path={
                        ROUTES.REWARDS_CENTER.EDIT_ITEM_DIGITAL + '/:productId'
                      }
                      element={<EditDigitalItem />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.NEW_ITEM_PHYSICAL}
                      element={<CreatePhysicalItem />}
                    />
                    <Route
                      path={
                        ROUTES.REWARDS_CENTER.EDIT_ITEM_PHYSICAL + '/:productId'
                      }
                      element={<EditPhysicalItem />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.NEW_ITEM_CODE}
                      element={<CreatePhysicalItem isCodeItem />}
                    />

                    <Route
                      path={ROUTES.REWARDS_CENTER.CATEGORY_MANAGEMENT}
                      element={<CategoryManagement />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.NEW_CATEGORY_MANAGEMENT}
                      element={<CreateCategory />}
                    />
                    <Route
                      path={
                        ROUTES.REWARDS_CENTER.EDIT_CATEGORY_MANAGEMENT +
                        '/:categoryId'
                      }
                      element={<EditCategory />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.COLLECTION_MANAGEMENT}
                      element={<CollectionManagement />}
                    />
                    <Route
                      path={ROUTES.REWARDS_CENTER.NEW_COLLECTION_MANAGEMENT}
                      element={<NewOrEditCollection />}
                    />
                    <Route
                      path={
                        ROUTES.REWARDS_CENTER.EDIT_COLLECTION_MANAGEMENT +
                        '/:collectionId'
                      }
                      element={<NewOrEditCollection />}
                    />
                    <Route
                      path={`${ROUTES.REWARDS_CENTER.COLLECTION_MANAGEMENT}/:collectionId`}
                      element={<CollectionDetails />}
                    />
                  </Route>
                  {/* End route marketplace */}

                  {/* Start route gacha */}
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path={ROUTES.GACHA.MANAGEMENT}
                      element={<GachaManagement />}
                    />
                    <Route
                      path={ROUTES.GACHA.VIEW_DETAIL}
                      element={<GachaDetail />}
                    />
                    <Route
                      path={ROUTES.GACHA.NEW_GACHA}
                      element={<CreateUpdateGacha />}
                    />
                    <Route
                      path={ROUTES.GACHA.EDIT_GACHA}
                      element={<CreateUpdateGacha />}
                    />
                    <Route
                      path={ROUTES.GACHA.MINT_TICKET}
                      element={<MintGachaTicket />}
                    />
                  </Route>
                  {/* End route gacha */}

                  {/* Start route bounty */}
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path={ROUTES.COM_SETTING.INDEX}
                      element={<CommunitySetting />}
                    />
                    <Route
                      path={ROUTES.MEMBER.INDEX}
                      element={<MemberManagement />}
                    />
                    <Route
                      path={ROUTES.BOUNTY.INDEX}
                      element={<BountyManagement />}
                    />
                    <Route
                      path={ROUTES.BOUNTY.ADD_NEW + '/:bountyType'}
                      element={<CreateAndUpdate />}
                    />
                    <Route
                      path={ROUTES.BOUNTY.EDIT + '/:bountyId'}
                      element={<CreateAndUpdate />}
                    />
                    <Route
                      path={ROUTES.BOUNTY.INDEX + '/:bountyId'}
                      element={<AdminBountyDetails />}
                    />
                    <Route
                      path={
                        ROUTES.BOUNTY.INDEX +
                        '/:bountyId' +
                        ROUTES.BOUNTY.USER_COMPLETED +
                        '/:taskId'
                      }
                      element={<UserCompleted />}
                    />
                    <Route
                      path={ROUTES.BOUNTY.CCP_LEADERBOARD}
                      element={<CCPLeaderboard />}
                    />
                    <Route
                      path={ROUTES.BOUNTY.REPORT}
                      element={<SummaryReport />}
                    />
                    {/* Start route NFT Badge */}
                    <Route
                      path={ROUTES.NFT_BADGE.INDEX}
                      element={<NftBadge />}
                    />
                    <Route
                      path={ROUTES.NFT_BADGE.ADD_NEW}
                      element={<NewNftBadge />}
                    />
                    <Route
                      path={ROUTES.NFT_BADGE.INDEX + '/:itemId'}
                      element={<NftDetails />}
                    />
                    <Route
                      path={ROUTES.MINT_NFT.NFT_1155}
                      element={<MintNft1155 />}
                    />
                    <Route path={ROUTES.MINT_NFT.A8} element={<MintA8NFT />} />
                    <Route
                      path={ROUTES.MINT_NFT.SOLANA}
                      element={<NewSolanaNFT />}
                    />
                    {/* End route NFT Badge  */}
                    <Route
                      path={ROUTES.DAILY_CHECK_IN.INDEX}
                      element={<DailyCheckIn />}
                    />
                    <Route
                      path={ROUTES.DAILY_CHECK_IN.REPORT}
                      element={<DailyCheckInReport />}
                    />
                    <Route
                      path={ROUTES.CAMPAIGN.INDEX}
                      element={<CampaignManagement />}
                    />
                    <Route
                      path={ROUTES.CAMPAIGN.NEW_CAMPAIGN}
                      element={<CampaignFormSetUp />}
                    />
                    <Route
                      path={ROUTES.CAMPAIGN.EDIT_CAMPAIGN + '/:campaignId'}
                      element={<CampaignFormSetUp />}
                    />
                    {/* Start route Ads */}
                    <Route
                      path={ROUTES.ADS.ADS_MANAGEMENT}
                      element={<AdsManagement />}
                    />
                    <Route
                      path={ROUTES.ADS.NEW_ADS}
                      element={<CreateUpdateAds />}
                    />
                    <Route
                      path={ROUTES.ADS.EDIT_ADS}
                      element={<CreateUpdateAds />}
                    />
                    {/* End route Ads  */}
                    <Route
                      path="*"
                      element={<Navigate to={ROUTES.BOUNTY.INDEX} />}
                    />
                  </Route>
                  {/* End route bounty */}

                  {/* Start route redemption */}
                  <Route element={<PrivateRoutes />}>
                    <Route
                      path={ROUTES.REDEMPTION.INDEX}
                      element={<RedemptionManagement />}
                    />
                    <Route
                      path={ROUTES.REDEMPTION.NEW_REDEMPTION}
                      element={<CreateUpdateRedemption />}
                    />
                    <Route
                      path={ROUTES.REDEMPTION.EDIT_REDEMPTION}
                      element={<CreateUpdateRedemption />}
                    />
                  </Route>
                  {/* End route redemption */}
                </Routes>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Footer />
      </Col>
      <Watcher />
    </Row>
  )
}

export default View
