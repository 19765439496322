import { Fragment, useEffect, useState } from 'react'

import { Button, Card, Col, Image, Modal, Row, Space, Typography } from 'antd'
import { PlusOutlined as AddIcon } from '@ant-design/icons'

import SaveWhitelistModal from './SaveWhitelistModal'

import { useWhitelistInfinite } from 'hooks/whitelist/useWhitelist'

import whitelistImg from 'static/images/gacha/whitelist.png'

type SelectWhitelistProps = {
  value?: string
  onChange?: (value: string) => void
}

function SelectWhitelist({ value, onChange }: SelectWhitelistProps) {
  const [open, setOpen] = useState(false)
  const [selectedWhitelistId, setSelectedWhitelistId] = useState(value)

  const {
    data: whitelists,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useWhitelistInfinite({})

  useEffect(() => {
    value && setSelectedWhitelistId(value)
  }, [value])

  return (
    <Fragment>
      <Button className="btn-square" onClick={() => setOpen(true)}>
        <AddIcon />
        {!!value ? 'Change' : 'Add'}
        <Fragment />
        Whitelist
      </Button>

      <Modal
        open={open}
        footer={null}
        style={{ maxWidth: 932 }}
        onCancel={() => setOpen(false)}
        width="unset"
      >
        <Row gutter={[0, 16]}>
          <Col span={24}>
            <Typography.Title
              level={3}
              type="success"
              style={{ textAlign: 'center' }}
            >
              Select Whitelist
            </Typography.Title>
          </Col>

          <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
            <SaveWhitelistModal selectedWhitelistId={selectedWhitelistId} />
          </Col>

          <Col
            span={24}
            style={{ width: '100%', maxHeight: '470px', overflowY: 'auto' }}
          >
            <Space style={{ justifyContent: 'center' }} wrap={true}>
              {!whitelists.length && (
                <Typography.Text>No Whitelist found!</Typography.Text>
              )}

              {!!whitelists.length &&
                whitelists.map((whitelist) => {
                  const {
                    _id: whitelistId,
                    description,
                    title,
                    thumbnail,
                  } = whitelist

                  return (
                    <Card
                      key={whitelistId}
                      hoverable
                      style={{ width: 159 }}
                      bodyStyle={{
                        padding: 0,
                        border:
                          selectedWhitelistId === whitelistId
                            ? '2px solid #42BAB0'
                            : '2px solid #242F30',
                      }}
                      onClick={() => setSelectedWhitelistId(whitelistId)}
                    >
                      <Row>
                        <Col span={24}>
                          <Image
                            src={thumbnail ?? whitelistImg}
                            fallback={whitelistImg}
                            alt="whitelist"
                            style={{ aspectRatio: 1, objectFit: 'cover' }}
                            preview={false}
                          />
                        </Col>

                        <Col span={24} style={{ padding: '8px 12px' }}>
                          <Typography.Title level={5} ellipsis>
                            {title}
                          </Typography.Title>

                          <Typography.Text ellipsis type="secondary">
                            {description}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
            </Space>
          </Col>

          <Col span={24}>
            {!!whitelists.length && hasNextPage && (
              <Row justify="center">
                <Button
                  type="dashed"
                  size="small"
                  shape="round"
                  style={{ borderRadius: 2 }}
                  onClick={() => fetchNextPage()}
                  loading={isFetchingNextPage}
                  disabled={!hasNextPage}
                >
                  Load More
                </Button>
              </Row>
            )}
          </Col>

          <Col span={24}>
            <Row justify="space-between">
              <Button type="primary" danger onClick={() => setOpen(false)}>
                Cancel
              </Button>

              <Button
                type="primary"
                size="large"
                shape="round"
                style={{ borderRadius: 2 }}
                onClick={() => {
                  onChange && onChange(selectedWhitelistId ?? '')
                  setOpen(false)
                }}
              >
                Select
              </Button>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default SelectWhitelist
