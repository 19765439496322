import { marketplaceApi as api } from 'services/base-axios'
import { getAccessToken } from 'services/utils'

import configs from 'configs'

import type { Data, DataList } from 'types'
import type {
  CreateWhitelistDto,
  FindAllWhitelistDto,
  IWhitelist,
  UpdateWhitelistDto,
} from 'types/whitelist.type'

const CONTROLLER = '/admin/whitelist'

class Whitelist {
  async getById(id: string): Promise<Data<IWhitelist>> {
    const { data } = await api.get(`${CONTROLLER}/${id}`)
    return data
  }

  async getAll(
    params: FindAllWhitelistDto,
  ): Promise<DataList<Data<IWhitelist>>> {
    const { data } = await api.get(CONTROLLER, {
      params,
    })
    return data
  }

  async create(dto: CreateWhitelistDto): Promise<Data<IWhitelist>> {
    const { data } = await api.post(CONTROLLER, dto)
    return data
  }

  async update(id: string, dto: UpdateWhitelistDto): Promise<Data<IWhitelist>> {
    const { data } = await api.patch(`${CONTROLLER}/${id}`, dto)
    return data
  }

  getDownloadUserWhitelistUrl(whitelistIds: string[]) {
    const accessToken = getAccessToken()

    const searchParams = new URLSearchParams()
    whitelistIds.forEach((whitelistId) =>
      searchParams.append('whitelistIds', whitelistId),
    )
    searchParams.append('accessToken', accessToken)

    const url = new URL(
      configs.api.marketplaceApi +
        CONTROLLER +
        '/export-whitelist?' +
        searchParams,
    )

    return url.toString()
  }
}

export const WhitelistService = new Whitelist()
