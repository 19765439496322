import { useNavigate } from 'react-router-dom'

import { Dropdown } from 'antd'
import IonIcon from 'components/systems/ionIcon'

import { BountyState, TableBountyActions } from 'constant/bounty'
import { useHandlePublishBounty } from 'hooks/bounty/useBounties'
import { ROUTES } from 'constant/routes'
import { BountyData } from 'services/bountySystem/bounty'
import { useSetBounties } from '../index'
import { useFilterProps } from '../newAndFilterBar'
import { notifyError, notifySuccess } from 'helper'

type RowActionProps = { bountyData: BountyData }
const RowAction = ({ bountyData }: RowActionProps) => {
  const [bounties, setBounties] = useSetBounties()
  const [{ status }] = useFilterProps()
  const { _id: bountyId, isEnabled } = bountyData
  const navigate = useNavigate()
  const { loading, handlePublishBounty } = useHandlePublishBounty(bountyId)
  const { Publish } = BountyState

  const onDelete = (bountyId: string) => {
    // TODO: Implement
  }

  const onReport = (bountyId: string) => {
    // TODO: Implement
  }

  const handleBountyState = async () => {
    try {
      const clnBounties = [...bounties]
      const bountyIndex = clnBounties.findIndex(({ _id }) => bountyId === _id)

      if (bountyIndex === -1) return

      const nextState = !isEnabled
      await handlePublishBounty(nextState)
      clnBounties[bountyIndex].isEnabled = nextState

      const publish = status === Publish
      setBounties(clnBounties.filter(({ isEnabled }) => isEnabled === publish))
      notifySuccess(
        `Mission #${clnBounties[bountyIndex]._id} has been ${
          nextState ? 'published' : 'unpublished'
        }`,
      )
    } catch (err) {
      notifyError(err)
    }
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Button
        trigger={['click']}
        type="text"
        icon={<IonIcon name="ellipsis-vertical-outline" />}
        menu={{
          items: [
            {
              label: TableBountyActions.Edit,
              key: TableBountyActions.Edit,
            },
            {
              label: bountyData.isEnabled
                ? TableBountyActions.UnPublish
                : TableBountyActions.Publish,
              key: bountyData.isEnabled
                ? TableBountyActions.UnPublish
                : TableBountyActions.Publish,
            },
          ],
          onClick: (e) => {
            switch (e.key) {
              case TableBountyActions.Edit:
                return navigate(`${ROUTES.BOUNTY.EDIT}/${bountyId}`)
              case TableBountyActions.Delete:
                return onDelete(bountyId)
              case TableBountyActions.Publish:
              case TableBountyActions.UnPublish:
                return handleBountyState()
              case TableBountyActions.Schedule:
                // TODO: Implement
                return
              case TableBountyActions.Report:
                return onReport(bountyId)
            }
          },
        }}
        onClick={(e) => e.stopPropagation()}
        loading={loading}
      />
    </div>
  )
}

export default RowAction
