import { Fragment, useEffect, useState } from 'react'

import { Button, Form, FormInstance, Modal, Row, Space, Typography } from 'antd'

import InputLabel from 'components/systems/input/InputLabel'
import UploadImageSync from 'components/systems/uploadPicture/UploadImageSync'

import { useCreateWhitelist } from 'hooks/whitelist/useCreateWhitelist'
import { useWhitelistInfinite } from 'hooks/whitelist/useWhitelist'
import { useUpdateWhitelist } from 'hooks/whitelist/useUpdateWhitelist'
import { useWhitelistDetail } from 'hooks/whitelist/useWhitelistDetail'

import { notifyError, notifySuccess } from 'helper'

import { CreateWhitelistDto, UpdateWhitelistDto } from 'types/whitelist.type'

type SaveWhitelistModalProps = {
  selectedWhitelistId?: string
}

function SaveWhitelistModal({ selectedWhitelistId }: SaveWhitelistModalProps) {
  const [form] = Form.useForm<CreateWhitelistDto | UpdateWhitelistDto>()
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  return (
    <Fragment>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            setIsEdit(true)
            setOpen(true)
          }}
          disabled={!selectedWhitelistId}
        >
          Edit Whitelist
        </Button>

        <Button
          type="primary"
          onClick={() => {
            setIsEdit(false)
            setOpen(true)
          }}
        >
          Create Whitelist
        </Button>
      </Space>

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false)
          form.resetFields()
        }}
        footer={null}
      >
        <Typography.Title
          level={3}
          type="success"
          style={{ textAlign: 'center', marginBottom: 16 }}
        >
          {isEdit ? 'Edit Whitelist' : 'Create New Whitelist'}
        </Typography.Title>

        <SaveWhitelistForm
          hideModal={() => setOpen(false)}
          form={form}
          isEdit={isEdit}
          selectedWhitelistId={selectedWhitelistId}
        />
      </Modal>
    </Fragment>
  )
}

type SaveWhitelistFormProps = {
  selectedWhitelistId?: string
  isEdit: boolean
  form: FormInstance<CreateWhitelistDto | UpdateWhitelistDto>
  hideModal: () => void
}
const SaveWhitelistForm = ({
  selectedWhitelistId,
  isEdit,
  form,
  hideModal,
}: SaveWhitelistFormProps) => {
  const image = Form.useWatch('thumbnail', form)

  const [isCustomImage, setIsCustomImage] = useState(false)

  const { mutateAsync: createWhitelist, isLoading: isCreating } =
    useCreateWhitelist()
  const { mutateAsync: updateWhitelist, isLoading: isUpdating } =
    useUpdateWhitelist()

  const { data: whitelistDetail } = useWhitelistDetail(selectedWhitelistId)
  const { refetchRoot } = useWhitelistInfinite({})

  useEffect(() => {
    setIsCustomImage(!!image)
  }, [image])

  useEffect(() => {
    if (isEdit && whitelistDetail) {
      form.setFieldsValue(whitelistDetail)
    }
  }, [whitelistDetail, isEdit, form])

  return (
    <Form
      form={form}
      onFinish={async (values) => {
        try {
          if (isEdit) {
            if (!selectedWhitelistId) return
            await updateWhitelist({
              whitelistId: selectedWhitelistId,
              payload: values as UpdateWhitelistDto,
            })
          } else {
            await createWhitelist(values as CreateWhitelistDto)
          }

          await refetchRoot()
          hideModal()
          notifySuccess('Save Whitelist')
        } catch (e) {
          notifyError('Save Whitelist Failed')
        }
      }}
    >
      <Form.Item
        name="title"
        rules={[{ required: true, message: 'Please enter title' }]}
      >
        <InputLabel label="Name" placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Please enter title' }]}
      >
        <InputLabel label="Description" placeholder="Enter description" />
      </Form.Item>

      <Form.Item
        name="inputPlaceholder"
        rules={[
          {
            message: 'Placeholder must be less than 60 characters',
            max: 60,
          },
        ]}
      >
        <InputLabel
          label="Input placeholder"
          placeholder="Enter input placeholder"
        />
      </Form.Item>

      <InputLabel.Switch
        label="Custom Image Upload"
        checked={isCustomImage}
        onChange={setIsCustomImage}
      />

      <Form.Item
        name="thumbnail"
        style={{
          height: isCustomImage ? 'fit-content' : 0,
          transition: 'all 0.3s ease-in-out',
          overflow: 'hidden',
          marginTop: isCustomImage ? 16 : 0,
        }}
        rules={[
          {
            validator: (_, value) => {
              if (isCustomImage && !value) {
                return Promise.reject(new Error('Image is required'))
              }

              return Promise.resolve()
            },
          },
        ]}
      >
        <UploadImageSync />
      </Form.Item>

      <Row justify="space-between">
        <Button type="primary" danger onClick={hideModal}>
          Cancel
        </Button>

        <Button
          type="primary"
          loading={isCreating || isUpdating}
          htmlType="submit"
        >
          Save
        </Button>
      </Row>
    </Form>
  )
}

export default SaveWhitelistModal
