import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Col, Row, Space, Tabs, Typography } from 'antd'
import {
  PlusCircleOutlined as PlusIcon,
  MenuOutlined as MenuIcon,
} from '@ant-design/icons/lib/icons'

import { WrapLoading } from 'components/systems/loading'
import PlayerListLeaderboardTask from 'view/player-leaderboard/task/listTask'
import Acceler8ListLeaderboardTask from '../../view/acceler8/task/listTask'

import { useAcceler8SubscriptionOverall } from 'hooks/acceler8/useAcceler8SubscriptionOverall'

import configs from 'configs'
import { ROUTES } from 'constant/routes'

import { LeaderboardTaskProps } from 'types/general-leaderboard/general-leaderboard.type'
import { ProgramType } from 'types/acceler8/acceler8-program.type'

import './index.less'

function LeaderboardTaskProvider({ type }: LeaderboardTaskProps) {
  const navigate = useNavigate()
  const [currentGameId, setCurrentGameId] = useState('')

  const { data: games, isLoading: loadingAcceler8Game } =
    useAcceler8SubscriptionOverall()

  const space3 = useMemo(
    () =>
      games?.find(
        (game: any) => game.communityId === configs.community.SP3CommunityId,
      ),
    [games],
  )

  const otherGames = useMemo(
    () =>
      games?.filter(
        (game: any) => game.communityId !== configs.community.SP3CommunityId,
      ),
    [games],
  )

  const routeNav = useMemo(() => {
    if (type === ProgramType.Player) {
      return {
        newGameTask: ROUTES.PLAYER_LEADERBOARD.NEW_GAME_TASK,
        newSpace3Task: ROUTES.PLAYER_LEADERBOARD.NEW_SPACE3_TASK,
      }
    }

    return {
      newGameTask: ROUTES.ACCELER8.NEW_GAME_TASK,
      newSpace3Task: ROUTES.ACCELER8.NEW_SPACE3_TASK,
    }
  }, [type])

  const tabItems = useMemo(() => {
    if (!games?.length) return []

    const otherGameTabs =
      otherGames?.map((game) => ({
        label: game.gameName,
        key: game.communityId,
      })) ?? []

    const space3Tab = {
      label: space3?.gameName ?? 'Space3',
      key: space3?.communityId ?? configs.community.SP3CommunityId,
    }

    return [...otherGameTabs, space3Tab]
  }, [space3, otherGames, games])

  useEffect(() => {
    setCurrentGameId(tabItems?.[0]?.key)
  }, [tabItems])

  return (
    <Row gutter={[0, 24]} className="leaderboard-task-management">
      <Col span={24}>
        <Row wrap={false} gutter={24} align="bottom">
          <Col flex={1} style={{ height: '100%' }}>
            <Row gutter={[0, 16]} align="stretch">
              <Col span={24}>
                <Typography.Title level={3} type="success">
                  Leaderboard Task Management
                </Typography.Title>
              </Col>

              <Col span={24}>
                <WrapLoading
                  loading={loadingAcceler8Game}
                  type="default"
                  size={30}
                >
                  <Tabs
                    items={tabItems}
                    className="leaderboard-game-tabs"
                    onChange={(activeKey) => {
                      setCurrentGameId(activeKey)
                    }}
                    style={{ maxWidth: 435 }}
                    moreIcon={<MenuIcon />}
                  />
                </WrapLoading>
              </Col>
            </Row>
          </Col>

          <Col>
            <Space direction="vertical">
              <Button
                onClick={() => navigate(routeNav.newGameTask)}
                type="primary"
                icon={<PlusIcon className="plus-icon" />}
              >
                Create New Task
              </Button>

              <Button
                onClick={() => navigate(routeNav.newSpace3Task)}
                type="primary"
                icon={<PlusIcon className="plus-icon" />}
              >
                Create Space3 Task
              </Button>
            </Space>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        {type === ProgramType.Player ? (
          <PlayerListLeaderboardTask gameId={currentGameId} type={type} />
        ) : (
          <Acceler8ListLeaderboardTask gameId={currentGameId} type={type} />
        )}
      </Col>
    </Row>
  )
}

export default LeaderboardTaskProvider
