import { Fragment } from 'react'

import { Form, FormInstance, Input, InputNumber, Radio } from 'antd'

import RequiredLabel from 'components/systems/requiredSymbol'
import DateRange from 'components/systems/dateRange'

import { RecurrentTime } from 'constant'
import { RECURRENT_TIME_OPTIONS } from './recurrent-time-option'

type BaseInfoSectionProps = {
  form: FormInstance
  isEdit?: boolean
}

function BaseInfoSection({ form, isEdit = false }: BaseInfoSectionProps) {
  const startedAt = Form.useWatch('startedAt', form)
  const endedAt = Form.useWatch('endedAt', form)
  const isAccumulative = Form.useWatch('isAccumulative', form)

  return (
    <Fragment>
      <Form.Item
        name="title"
        label={<RequiredLabel children="Task name" />}
        rules={[
          {
            required: true,
            min: 5,
            max: 200,
            message: 'Task name must be between 5 and 200 characters',
          },
        ]}
      >
        <Input placeholder="Enter Task name" />
      </Form.Item>

      <Form.Item
        name="points"
        label={<RequiredLabel children="Point per completion" />}
        rules={[
          {
            required: true,
            message: 'Please enter point per completion',
          },
          {
            type: 'number',
            message: 'Point per completion must be number',
          },
        ]}
      >
        <InputNumber placeholder="Enter point earned for this Task" />
      </Form.Item>

      <Form.Item
        name="tutorial"
        label={<RequiredLabel required={false} children="Tutorial link" />}
        rules={[
          {
            type: 'url',
            message: 'Invalid url',
          },
        ]}
      >
        <Input placeholder="Enter tutorial link" />
      </Form.Item>

      <Form.Item
        name="isAccumulative"
        label={<RequiredLabel children="Earning Type" />}
        initialValue={false}
        rules={[
          {
            required: true,
            message: 'Please select accumulate',
          },
        ]}
      >
        <Radio.Group disabled={isEdit}>
          <Radio value={false}>Recurrent</Radio>
          <Radio value={true}>Accumulative</Radio>
        </Radio.Group>
      </Form.Item>

      {!isAccumulative && (
        <Form.Item
          name="recurrenceTime"
          label={<RequiredLabel required={false} children="Recurrent Time" />}
          initialValue={RecurrentTime.OneTime}
        >
          <Radio.Group disabled={isEdit} options={RECURRENT_TIME_OPTIONS} />
        </Form.Item>
      )}

      <Form.Item name="startedAt" rules={[{ required: true }]} hidden />
      <Form.Item name="endedAt" rules={[{ required: true }]} hidden />

      <Form.Item
        name="taskDateRange" // name is only used for validation
        rules={[
          {
            validator: () => {
              if (!startedAt || !endedAt) {
                return Promise.reject(
                  new Error('Please select both start and end date'),
                )
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <DateRange
          startAt={startedAt}
          endAt={endedAt}
          onChangeStartAt={(value) => {
            form.setFieldsValue({ startedAt: value })
            form.validateFields(['taskDateRange'])
          }}
          onChangeEndAt={(value) => {
            form.setFieldsValue({ endedAt: value })
            form.validateFields(['taskDateRange'])
          }}
          disabled={{ start: isEdit && isAccumulative }} // disable start date when accumulative
        />
      </Form.Item>
    </Fragment>
  )
}

export default BaseInfoSection
